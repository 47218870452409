import { PageId } from '../../constants';
import { ComponentType } from '../../migration/domain';

export abstract class BasePageMigrator {
  public constructor(
    protected editorSDK,
    protected appToken,
    protected instance,
  ) {}

  public abstract shouldMigrate();

  public abstract execute();

  protected abstract get widgetId();

  protected async addBookingsPage(pageId: PageId) {
    return this.editorSDK.document.tpa.add.component(this.appToken, {
      componentType: this.editorSDK.document.tpa.TPAComponentType.Page,
      page: {
        pageId,
        shouldNavigate: false,
      },
    });
  }

  protected async updateComponentStyles(compId, style) {
    return this.editorSDK.document.components.style.update(this.appToken, {
      componentRef: { type: 'DESKTOP', id: compId },
      style,
    });
  }

  protected async updateComponentSettings(compId, config) {
    return this.editorSDK.document.controllers.saveConfiguration(
      this.appToken,
      {
        controllerRef: { type: 'DESKTOP', id: compId },
        config,
      },
    );
  }

  protected async getAllSitePages() {
    return this.editorSDK.pages.data.getAll();
  }

  protected async getSiteStructure() {
    const allComponents = await this.getAllSiteComponents();
    return Promise.all(
      allComponents.map((componentRef) =>
        this.getFullComponentStructure(componentRef),
      ),
    );
  }

  protected async createPageState(pageId: PageId, pageName: string) {
    const allSitePages = await this.getAllSitePages();
    const pageData = allSitePages.find((page) => page.tpaPageId === pageId);
    if (pageData) {
      await this.editorSDK.document.pages.setState(this.appToken, {
        state: {
          [pageName]: [{ id: pageData.id }],
        },
      });
    }
  }

  protected get bookingCheckoutWidgetId() {
    return '713f72a2-7a32-47e9-b5e4-6024ee57d277';
  }

  protected findWidgetStructure(siteStructure, widgetId) {
    return siteStructure.find(
      (comp) =>
        comp?.type === ComponentType.COMPONENT &&
        comp.data?.widgetId === widgetId,
    );
  }

  private async getFullComponentStructure(componentRef) {
    return this.editorSDK.document.components.serialize(this.appToken, {
      componentRef,
      maintainIdentifiers: true,
    });
  }

  private async getAllSiteComponents(): Promise<any[]> {
    return this.editorSDK.document.components.getAllComponents(this.appToken);
  }
}
