import { ServicePageMigrator } from './service-page-migrator';
import { CalendarPageMigrator } from './calendar-page-migrator';
import { FormPageMigrator } from './form-page-migrator';
import { EcomPagesMigrator } from './ecom-pages-migrator';
import { BasePageMigrator } from './base-page-migrator';

export const migrateToOOI = async ({
  editorSDK,
  appToken,
  instance,
  editorScriptApi,
  debug = false,
}) => {
  const pageMigrators: BasePageMigrator[] = [
    new ServicePageMigrator(editorSDK, appToken, instance, editorScriptApi),
    new CalendarPageMigrator(editorSDK, appToken, instance),
    new FormPageMigrator(editorSDK, appToken, instance),
    new EcomPagesMigrator(
      editorSDK,
      appToken,
      instance,
      editorScriptApi,
      debug,
    ),
  ];

  for (const pageMigrator of pageMigrators) {
    if (await pageMigrator.shouldMigrate()) {
      await pageMigrator.execute();
    }
  }
  console.log('migration completed successfully');
};
